import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { EntityDto } from "@entity/data/dto/entity.dto";
import { ActionLineEnumMapper } from "@entity/data/mappers/action-line-enum.mapper";
import { AddressMapper } from "@entity/data/mappers/address/address.mapper";
import { CollectiveEnumMapper } from "@entity/data/mappers/collective-enum.mapper";
import { CrosscuttingScopeEnumMapper } from "@entity/data/mappers/crosscutting-scope-enum.mapper";
import { TypologyEnumMapper } from "@entity/data/mappers/typology-enum.mapper";
import { Entity } from "@entity/domain/models/entity.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { ContactsMapper } from "./contacts.mapper";

@injectable()
export class EntityMapper {
    constructor(
        @inject(AddressMapper)
        private readonly addressMapper: AddressMapper,
        @inject(ContactsMapper)
        private readonly contactsMapper: ContactsMapper,
        @inject(CollectiveEnumMapper)
        private readonly collectiveEnumMapper: CollectiveEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
        @inject(TypologyEnumMapper)
        private readonly typologyEnumMapper: TypologyEnumMapper,
        @inject(ActionLineEnumMapper)
        private actionLineEnumMapper: ActionLineEnumMapper,
        @inject(CrosscuttingScopeEnumMapper)
        private crosscuttingScopeEnumMapper: CrosscuttingScopeEnumMapper,
    ) {}

    map(entityDto: EntityDto): Nullable<Entity> {
        const errors = validateSync(entityDto);

        if (errors.length > 0) {
            console.error(errors);
            return null;
        }

        const entity = new Entity(
            entityDto.id,
            entityDto.company_name,
            entityDto.is_active,
        );

        if (entityDto.legal_typology) {
            entity.typologyId = this.typologyEnumMapper.map(
                entityDto.legal_typology,
            );
        }

        if (entityDto.priority_collective) {
            entity.collectiveId = this.collectiveEnumMapper.map(
                entityDto.priority_collective,
            );
        }

        if (entityDto.secondary_collectives) {
            entity.secondaryCollectives =
                entityDto.secondary_collectives.mapNotNull(
                    (secondaryCollective) =>
                        this.collectiveEnumMapper.map(secondaryCollective),
                );
        }

        if (entityDto.action_lines) {
            entity.actionLines = entityDto.action_lines.mapNotNull(
                (actionLine) => this.actionLineEnumMapper.map(actionLine),
            );
        }

        if (entityDto.documents_data) {
            entity.documents = entityDto.documents_data.mapNotNull((document) =>
                this.documentMapper.map(document),
            );
        }

        if (entityDto.legal_constitution_date) {
            entity.legalConstitutionDate = DateTime.fromISO(
                entityDto.legal_constitution_date,
            );
        }

        if (entityDto.crosscutting_entity_scopes) {
            entity.crosscuttingScopes =
                entityDto.crosscutting_entity_scopes.mapNotNull(
                    (coscuttingScopeCollective) =>
                        this.crosscuttingScopeEnumMapper.map(
                            coscuttingScopeCollective,
                        ),
                );
        }

        entity.actionLinesDescription = convertEmptyToUndefined(
            entityDto.action_lines_description,
        );
        entity.cnaeCode = convertEmptyToUndefined(entityDto.cnae_code);
        entity.entityPurpose = convertEmptyToUndefined(
            entityDto.entity_purpose,
        );
        entity.identification = convertEmptyToUndefined(
            entityDto.identification,
        );
        entity.otherActionLine = convertEmptyToUndefined(
            entityDto.other_action_line,
        );
        entity.otherCollective = convertEmptyToUndefined(
            entityDto.other_collectives,
        );
        entity.otherCrosscuttingScope = convertEmptyToUndefined(
            entityDto.other_crosscutting_entity_scope,
        );
        entity.otherTypology = convertEmptyToUndefined(
            entityDto.other_legal_typology,
        );
        entity.webAddress = convertEmptyToUndefined(entityDto.web_address);

        entity.address = entityDto.address_data.mapNotNull((address) =>
            this.addressMapper.map(address),
        );

        entity.contacts = entityDto.contacts_data.mapNotNull((contact) =>
            this.contactsMapper.map(contact),
        );

        entity.publicInterest = entityDto.public_interest ?? false;

        entity.equalityPlan = entityDto.equality_plan ?? false;

        if (entityDto.equality_plan_expiration_date) {
            entity.equalityPlanExpirationDate = entityDto.equality_plan
                ? DateTime.fromISO(entityDto.equality_plan_expiration_date)
                : undefined;
        }

        return entity;
    }
}
