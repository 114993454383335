import { CreateRoom, Room } from "./room.model";

import { IdentificationDocumentTypeEnum } from "@core/domain/models/identification-document-type.model";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { CostPeriodicityTypeEnum } from "./cost-periodicity.model";
import { StatusTypeEnum } from "./status.model";
import { CountryEnum } from "@core/domain/models/country.model";

export class Status {
    constructor(
        public readonly id: number,
        public name: string,
    ) {}
}

export class Local {
    public identificationType: Undefinable<IdentificationDocumentTypeEnum>;
    public identification: Undefinable<string>;
    public businessName: Undefinable<string>;
    public floor: Undefinable<string>;
    public door: Undefinable<string>;
    public stair: Undefinable<string>;
    public localCode: Undefinable<string>;
    public cadastralReference: Undefinable<string>;
    public region: Undefinable<string>;
    public country: Undefinable<CountryEnum>;
    public otherStatus: Undefinable<string>;

    constructor(
        public readonly id: number,
        public entityId: number,
        public name: string,
        public costPeriodicity: CostPeriodicityTypeEnum,
        public status: StatusTypeEnum,
        public cost: number,
        public streetName: string,
        public streetNumber: string,
        public zipCode: string,
        public town: string,
        public rooms: Room[],
    ) {}
}

export class Locals {
    constructor(
        public readonly locals: Local[],
        public readonly count: number,
    ) {}
}

export class CreateLocal {
    public identificationType: Undefinable<Local["identificationType"]>;
    public identification: Undefinable<Local["identification"]>;
    public businessName: Undefinable<Local["businessName"]>;
    public floor: Undefinable<Local["floor"]>;
    public door: Undefinable<Local["door"]>;
    public stair: Undefinable<Local["stair"]>;
    public localCode: Undefinable<Local["localCode"]>;
    public cadastralReference: Undefinable<Local["cadastralReference"]>;
    public region: Undefinable<Local["region"]>;
    public country: Undefinable<Local["country"]>;
    public otherStatus: Undefinable<Local["otherStatus"]>;

    constructor(
        public readonly name: Local["name"],
        public entityId: Local["entityId"],
        public costPeriodicity: Local["costPeriodicity"],
        public status: Local["status"],
        public cost: Local["cost"],
        public streetName: Local["streetName"],
        public streetNumber: Local["streetNumber"],
        public zipCode: Local["zipCode"],
        public town: Local["town"],
        public rooms: (CreateRoom | Room)[],
    ) {}
}

export class EditLocal extends CreateLocal {
    constructor(
        public readonly id: number,
        entityId: Local["entityId"],
        name: Local["name"],
        costPeriodicity: Local["costPeriodicity"],
        status: Local["status"],
        cost: Local["cost"],
        streetName: Local["streetName"],
        streetNumber: Local["streetNumber"],
        zipCode: Local["zipCode"],
        town: Local["town"],
        rooms: (CreateRoom | Room)[],
    ) {
        super(
            name,
            entityId,
            costPeriodicity,
            status,
            cost,
            streetName,
            streetNumber,
            zipCode,
            town,
            rooms,
        );
    }
}
