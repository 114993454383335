import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { CreateEntityBody } from "@entity/data/dto/create-entity.body";
import { ActionLineEnumMapper } from "@entity/data/mappers/action-line-enum.mapper";
import { CategoryTypeEnumMapper } from "@entity/data/mappers/category-type-enum.mapper";
import { CollectiveEnumMapper } from "@entity/data/mappers/collective-enum.mapper";
import { CrosscuttingScopeEnumMapper } from "@entity/data/mappers/crosscutting-scope-enum.mapper";
import { TypologyEnumMapper } from "@entity/data/mappers/typology-enum.mapper";
import { CreateEntity } from "@entity/domain/models/create-entity.model";
import { CreateUserEntity } from "@entity/domain/models/create-lite-entity.model";
import { CreateEntityFormValuesValidated } from "@entity/presentation/component/entity-form/entity-form.component";
import { inject, injectable } from "inversify";

@injectable()
export class CreateEntityMapper {
    constructor(
        @inject(CategoryTypeEnumMapper)
        private readonly categoryTypeEnumMapper: CategoryTypeEnumMapper,
        @inject(CollectiveEnumMapper)
        private readonly collectiveEnumMapper: CollectiveEnumMapper,
        @inject(TypologyEnumMapper)
        private typologyEnumMapper: TypologyEnumMapper,
        @inject(ActionLineEnumMapper)
        private actionLineEnumMapper: ActionLineEnumMapper,
        @inject(CrosscuttingScopeEnumMapper)
        private crosscuttingScopeEnumMapper: CrosscuttingScopeEnumMapper,
    ) {}

    mapFromEntityFormValues(
        entityForm: CreateEntityFormValuesValidated,
    ): CreateEntity {
        const entity = new CreateEntity(
            entityForm.general.name,
            entityForm.general.typologyId,
            entityForm.general.collectiveId,
            entityForm.general.actionLines,
            entityForm.general.secondaryCollectives,
            entityForm.general.crosscuttingScopes,
        );

        entity.identificationDocument = convertEmptyToUndefined(
            entityForm.general.identificationDocument,
        );
        entity.cnaeCode = convertEmptyToUndefined(entityForm.general.cnaeCode);
        entity.otherCollective = convertEmptyToUndefined(
            entityForm.general.otherCollective,
        );
        entity.otherTypology = convertEmptyToUndefined(
            entityForm.general.otherTypology,
        );
        entity.otherActionLine = convertEmptyToUndefined(
            entityForm.general.otherActionLine,
        );
        entity.actionLinesDescription = convertEmptyToUndefined(
            entityForm.general.actionLinesDescription,
        );
        entity.otherCrosscuttingScope = convertEmptyToUndefined(
            entityForm.general.otherCrosscuttingScope,
        );
        entity.entityPurpose = convertEmptyToUndefined(
            entityForm.general.entityPurpose,
        );
        entity.webAddress = convertEmptyToUndefined(
            entityForm.general.webAddress,
        );
        entity.legalConstitutionDate = convertEmptyToUndefined(
            entityForm.general.legalConstitutionDate,
        );

        entity.publicInterest = entityForm.general.publicInterest;

        entity.equalityPlan = entityForm.general.equalityPlan;

        entity.equalityPlanExpirationDate = convertEmptyToUndefined(
            entityForm.general.equalityPlanExpirationDate,
        );

        return entity;
    }

    mapToCreateDto(
        createEntity: CreateEntity | CreateUserEntity,
    ): CreateEntityBody {
        if (createEntity instanceof CreateUserEntity) {
            const entityBody: CreateEntityBody = {
                company_name: createEntity.name,
            };

            return entityBody;
        }

        const entityBody: CreateEntityBody = {
            company_name: createEntity.name,
        };

        if (createEntity.collectiveId) {
            entityBody.priority_collective = this.collectiveEnumMapper.mapToDto(
                createEntity.collectiveId,
            );
        }

        if (createEntity.typologyId) {
            entityBody.legal_typology = this.typologyEnumMapper.mapToDto(
                createEntity.typologyId,
            );
        }

        if (createEntity.actionLines) {
            entityBody.action_lines = createEntity.actionLines.map(
                (actionLine) => this.actionLineEnumMapper.mapToDto(actionLine),
            );
        }

        if (createEntity.secondaryCollectives) {
            entityBody.secondary_collectives =
                createEntity.secondaryCollectives.map((secondaryCollective) =>
                    this.collectiveEnumMapper.mapToDto(secondaryCollective),
                );
        }

        if (createEntity.crosscuttingScopes) {
            entityBody.crosscutting_entity_scopes =
                createEntity.crosscuttingScopes.map(
                    (coscuttingScopeCollective) =>
                        this.crosscuttingScopeEnumMapper.mapToDto(
                            coscuttingScopeCollective,
                        ),
                );
        }

        entityBody.action_lines_description = convertEmptyToUndefined(
            createEntity.actionLinesDescription,
        );
        entityBody.cnae_code = convertEmptyToUndefined(createEntity.cnaeCode);
        entityBody.entity_purpose = convertEmptyToUndefined(
            createEntity.entityPurpose,
        );
        entityBody.identification = convertEmptyToUndefined(
            createEntity.identificationDocument,
        );
        entityBody.legal_constitution_date = convertEmptyToUndefined(
            createEntity.legalConstitutionDate?.toISODate(),
        );
        entityBody.other_action_line = convertEmptyToUndefined(
            createEntity.otherActionLine,
        );
        entityBody.other_collectives = convertEmptyToUndefined(
            createEntity.otherCollective,
        );
        entityBody.other_crosscutting_entity_scope = convertEmptyToUndefined(
            createEntity.otherCrosscuttingScope,
        );
        entityBody.other_legal_typology = convertEmptyToUndefined(
            createEntity.otherTypology,
        );
        entityBody.web_address = convertEmptyToUndefined(
            createEntity.webAddress,
        );

        entityBody.public_interest = createEntity.publicInterest;

        entityBody.equality_plan = createEntity.equalityPlan;

        entityBody.equality_plan_expiration_date = convertEmptyToUndefined(
            createEntity.equalityPlanExpirationDate?.toISODate(),
        );

        return entityBody;
    }

    mapFromName(newEntityName: string): CreateUserEntity {
        return new CreateUserEntity(newEntityName);
    }
}
